import './App.css'
import React from 'react'
import VaccineContextProvider from './context/VaccineContext'

const NonAuthenticatedApp = React.lazy(() => import('./NonAuthenticatedApp'))

function App() {
  return (
    <React.Suspense fallback={<div />}>
      <VaccineContextProvider>
        <NonAuthenticatedApp />
      </VaccineContextProvider>
    </React.Suspense>
  )
}

export default App
