import i18n from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { web as resources } from '../locales'

const detection = {
  lookupLocalStorage: 'vaccine-scanner-accept-language',
  caches: ['localStorage'],
}

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    ns: ['translations-web'],
    defaultNS: 'translations-web',
    detection,
    fallbackLng: 'es',
    resources,
    saveMissing: process.env.NODE_ENV === 'development',
    missingKeyHandler(lng, ns, key) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          `%c 🚨 Hacen falta esta key en el archivo ${ns} 🚨 `,
          'color: red'
        )
        console.log(`%c Key: ${key}`, 'color: black')
      }
    },
    interpolation: {
      escapeValue: false,
      prefix: '{{',
      suffix: '}}',
    },
  })

export default i18n
