import React, { createContext, useState } from 'react'

export const DataContext = createContext()

export default function VaccineContextProvider({ children }) {
  const [state, setState] = useState('')
  return (
    <div>
      <DataContext.Provider value={{ state, setState }}>
        {children}
      </DataContext.Provider>
    </div>
  )
}
